import React from 'react';
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../../components/seo";
import Layout from "../../components/layout";
import Header from "../../components/header";
import Footer from "../../components/footer";

const DinPage = () => {

  return (
    <Layout classmain="din">
      <Seo
        title='DiN | MOBILE GAME'
        metaDesciption='Endless runner with Match 3 games elements. Train your brain and attention with this bright game. Follow game changes and get the max points! How long will you keep up?'
      />
      <Header/>
      <div className='din__top'>
        <div className='din__top-container container'>
          <div className='din__intro'>
            <StaticImage
              src='../images/dinlogo.jpg'
              className='din__intro--logo'
              alt="DIN: Mobile Game"
            />
            <div className='din__intro--right'>
              <div className='din__intro--head'>
                <h1>DiN Runner</h1>
                <span className='vl'>4+</span>
              </div>
              <p>Challenge, Match 3 Colors, Casual, Arcade</p>
              <a href='#ddl' className='ddl'>Download for mobile</a>
            </div>
          </div>
          <div className='din__descr'>
            <p>
            We're excited to announce the top players of 2022 in our mobile game! After months of intense competition, we've selected the best of the best to be recognized for their skill and dedication. These players have shown exceptional ability in navigating the challenges of our game and have truly stood out among the thousands of players who have participated.
<br/>
            We want to extend our sincerest congratulations to all the winners. Your hard work and dedication have truly paid off and we're thrilled to be able to recognize your achievements.
            <br/>
            We would also like to take a moment to thank all of the players who have participated in our game. Your support and engagement have made this experience possible and we're so grateful for all of you.
            <br/>
            Keep an eye out for future updates and events, and let's continue to celebrate the best mobile gaming has to offer.<br/><br/>
            </p>
            <p style={{
              color:'#fcca4e',
              fontWeight:"700"}}>
DinSebas<br/>
DinSarah<br/>
DinMilis<br/>
[POwe]*BRO(Quake III arena)<br/>
Dinvasilisa<br/>
DinKaspi<br/>
Kranik<br/>
!300/\/\<br/>
DinTewi<br/>
Din dong<br/>
DP<br/>
Lakebomber<br/>
dinKarsie<br/>
DinTijl<br/>
DinRuben<br/>
Bibas<br/>
            </p>
          </div>
        </div>
      </div>
      <div className="din__container container" id="ddl2">
        <div className="din__row row">
          <div className="din__game col-md-12 col-12">
            <div className="din__game--inner">
              <StaticImage
                src='../images/dinrunner.png'
                className='din__game--logo'
                alt="DIN: Mobile Game"
              />
              <h1 className="din__game--title">Available Now</h1>
              <div className="din__game--markets">
                  <a href="https://play.google.com/store/apps/details?id=com.oceandev.dinrunner3d"><img src="/images/android.svg"/></a>
                  <a href="https://apps.apple.com/us/app/din-3d/id1578459093"><img src="/images/ios.svg"/></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </Layout>
  )
}

export default DinPage
